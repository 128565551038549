var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from "vue";
import gql from "graphql-tag";
import PaymentsTable from "./PaymentsTable.vue";
import moment from "moment";
import reverse from "lodash/reverse";
export default Vue.extend({
    components: {
        PaymentsTable: PaymentsTable,
    },
    apollo: {
        viewerAgent: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query AgentLandingQuery($paymentsAmount: Int!) {\n          viewerAgent {\n            id\n            code\n            agencyName\n            contactPersonName\n            emailAddress\n            phoneNumber\n            studentsCount\n            activeStudentsCount\n            totalCommissionAmount\n            totalCommissionDue\n            priorYearMonthlyCommissions {\n              month\n              amount\n            }\n            ...PaymentsTable_agent\n          }\n        }\n        ", "\n      "], ["\n        query AgentLandingQuery($paymentsAmount: Int!) {\n          viewerAgent {\n            id\n            code\n            agencyName\n            contactPersonName\n            emailAddress\n            phoneNumber\n            studentsCount\n            activeStudentsCount\n            totalCommissionAmount\n            totalCommissionDue\n            priorYearMonthlyCommissions {\n              month\n              amount\n            }\n            ...PaymentsTable_agent\n          }\n        }\n        ", "\n      "])), PaymentsTable.options.fragments.agent),
            variables: { paymentsAmount: 3 },
        },
    },
    data: function () {
        return {
            // For typing of apollo
            viewerAgent: undefined,
            chartOptions: {
                legend: { position: "none" },
                colors: ["#FF3F0F"],
            },
        };
    },
    computed: {
        chartData: function () {
            if (!this.viewerAgent) {
                return [];
            }
            return __spreadArray([
                ["Month", "Commission"]
            ], reverse(this.viewerAgent.priorYearMonthlyCommissions).map(function (_a) {
                var amount = _a.amount, month = _a.month;
                return [moment(month).format("MMM YY"), parseFloat(amount)];
            }), true);
        },
    },
    metaInfo: function () {
        return {
            title: this.$t("Agent dashboard").toString(),
        };
    },
});
var templateObject_1;
